import React from "react";
import Main from "./views/Main.jsx";
import api from "./Api.js";
import $ from "jquery";
import { connect } from "react-redux";
import ErrorPage from "./views/pages/ErrorPage.jsx";
import GlobalFunctions from "./components/GlobalFunctions.jsx";
import axios from "axios";
import moment from "moment";

//import LogRocket from 'logrocket';

import Cookies from 'universal-cookie';
import { DataStateInfos } from "./hooks/useDataInfos.jsx";
const cookies = new Cookies();
window.cookies22 = cookies;
//if(window.location.origin !== "http://192.168.15.200:3020"){

//LogRocket.init('xfaecw/geeks-erp');

//}

window.erp = {};

require('moment/locale/pt-br');
require('moment/locale/en-au');
require('moment/locale/es');

class App extends React.Component {

  constructor(props) {
    super(props);

    var session = cookies.get('session');
    var userId = cookies.get('userId');

    //Para verificar se tem algum acesso pedido na url
    var url = new URL(window.location.href);
    var get_session = cookies.get('session');
    var get_user = cookies.get('userId');

    //caso o acesso seja por url
    if (get_session !== null && get_user !== null) {
      session = get_session;
      userId = get_user;

      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("session", session);


    }

    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if (session === null || userId === null) { session = ""; userId = ""; }

    this.state = { units: undefined, preloader: true, confs: {}, systemOK: false, userId: userId, session: session, carregouIp: false, menuMin: false, lessonsMinified: true, languageSelected: "pt-br", languages: [], languageTexts: {} };

    this.verificationInitial = this.verificationInitial.bind(this);
    this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
    this.language = this.language.bind(this);
    this.removeSession = this.removeSession.bind(this);



    window.app = this;
  }

  //retorna os testos da linguagem atual
  language(term) {
    if (term === undefined) {
      return this.state.languageTexts;
    } else {
      return this.state.languageTexts[term];
    }
  }

  //Verifica o backend
  async verificationInitial() {
    /*
          var file = window.location.hostname;
          var file2 = undefined;
          var file3 = undefined;
          var tps = 1;
          //file = 'vilamariana.geekserp.com.br';
    
          if(file.indexOf('geekserp.com.br') !== -1){
    
            var ddm = file.split('.');
            file = ddm[0]+'.geeks.business';
            file2 = ddm[0]+'.supergeeks.io';
            file3 = ddm[0]+'.supergeeks.io';
    
            console.log('File 1 novo: '+file);
            console.log('File 2 novo: '+file2);
            console.log('File 3 novo: '+file3);
    
          }
    
          var ip = await api.verifyBackend(file).then((res)=>{  return res.data; }).catch(error=>{ console.log(error.data); console.log(error); console.log(error.response); return false; });
          if(ip !== false){
    
            if(typeof ip === "string"){
    
              console.log('false rdc2');
              ip = false;
    
            }
    
          }
          
          if(ip === false && file2 !== undefined){
    
            ip = await api.verifyBackend(file2).then((res)=>{ return res.data; }).catch(error=>{ console.log(error.data); console.log(error); console.log(error.response); return false; });
            tps = 2;
    
          }
    
          if(typeof ip === "object"){
    
            if(ip.url === "/api"){
    
              if(tps === 1){ ip.url  = "https://"+file+"/api"; }
              if(tps === 2){ ip.url  = "https://"+file2+"/api"; }
    
              console.log('tps: '+tps+' ip.url:'+ip.url);
    
            }
    
          }
    
          console.log('SM1 ddd');
    
          console.log('ip: ');
          console.log(ip);
           
          if(ip === false){ console.log('Erro:01'); return false; }
          if(ip.url === undefined){ console.log('Erro:02'); return false; } 
    
    
          if(ip.url === "https://api.geekserp.com.br/mvm"){
    
            console.log('Reescreveu url dds:');
            ip.url = "https://api2.geekserp.com.br/mvm";
    
    
          }
    
          console.log('Verificando backend em : '+ip.url+'/sucesso');
          var backend = await api.verifyBackend2(ip.url+'/sucesso').then((res)=>{  return true; }).catch(error => { console.log(error.data);  console.log(error); console.log(error.response); return false;  });
    
    
          */


    var url = window.location.origin;
    var psa = url.replaceAll('https://', '');
    var splitc = psa.split('.');
    console.log('Empresa: ' + splitc);
    var urlCheck = 'https://erp-center-all.s3.sa-east-1.amazonaws.com/serverlist/' + splitc[0] + '.json';

    var backend = await axios.get(urlCheck).then((res) => {

      console.log(res.data);
      if (typeof res.data === "string") { res.data = JSON.parse(res.data); }
      return res.data;

    }).catch(error => { return false; });


    var dsc = window.parseQueryString();


    if (window.location.origin === "http://localhost:3000") {

      //backend = { url: "https://api3.geekserp.com.br" };
      backend = { url: "http://localhost:3030" };
      splitc[0] = "itu"; //splitc[0] = "mvm";

      //backend = { url: "https://api99.geekserp.com.br" };
      //splitc[0] = "mvm";

    }


    //teste
    if (window.location.origin === "http://localhost:3001") {

      backend = { url: "https://api1.geekserp.com.br" };
      splitc[0] = "live";

    }

    if(sessionStorage.backend_devlink !== undefined){ backend = { url: "https://"+sessionStorage.backend_devlink }; }
    if(sessionStorage.license_devlink !== undefined){ splitc[0] = sessionStorage.license_devlink; }

    if(dsc.backend !== undefined){ 
      
      backend = { url: "https://"+dsc.backend }; 
      sessionStorage.setItem('backend_devlink', dsc.backend);
      
    }

    if(dsc.license !== undefined){ 
      
      splitc[0] = dsc.license;
      sessionStorage.setItem('license_devlink', dsc.license);
      
    }


    // var backend = true;
    if (backend) {

      var backendURL = backend.url + '/' + splitc[0];
      window.backendURL = backendURL;
      console.log('Backend: ' + backendURL);

      sessionStorage.setItem('backend', backendURL);
      //this.verify();

      //Para buscar as configurações do sistema
      var confs = await api.loadConfig().then((res) => {

        console.log(res.data);
        return res.data;

      }).catch(error => { console.log(error.data); console.log(error); console.log(error.response); return false; });


      if (confs !== false) {

        this.setState({ confs: confs });

        //Para setar o css
        $("#customcss").html(confs.customcss);


      } else {

        console.log('Erro:04 load conf');
        return false;

      }

      this.setState({ carregouIp: true }); this.verify(1);
      return true;

    } else { console.log('O backend retornou false.'); return false; }





  }

  //Parar deslogar o usuario do sistema
  async removeSession() {

    console.log('removeSession');
    api.logout().then((res) => { }).catch((error) => { });;

    console.log("removeu tudo");
    sessionStorage.removeItem("session");
    sessionStorage.removeItem("userId");
    delete window.app.state.dataUser;
    delete window.app.state.user;
    this.setState({ session: "", userId: "" });

  }

  /*async checkSessionCenter(){

    var sessionCenter = window.parseQueryString().sessionCenter;
    if(sessionCenter !== undefined){

      await api.login({sessionCenter:sessionCenter}).then(res => {

        console.log(res.data);

        cookies.set('session',res.data.session,{path:'/'});
        cookies.set('userId',res.data.user.id,{path:'/'});

        sessionStorage.setItem('session',res.data.session);
        sessionStorage.setItem('userId',res.data.user.id);
    
        this.setState({user:res.data.user});
        this.props.dispatch({ type: "SET_DATA_USER", data: res.data.user });
        this.getLanguageTexts(res.data.user.preference.language + ".json",res.data.user.preference.language);
        
   
      }).catch((error) => {

        this.removeSession();
        
      });
     
    }

  }*/

  //para verificar no servidor se ele esta logado.
  async verify() {

    await api.verifyAcess({}).then(res => {

      if (this.state.user === undefined) {

        this.setState({ user: res.data });
        this.props.dispatch({ type: "SET_DATA_USER", data: res.data });
        this.getLanguageTexts(res.data.preference.language + ".json", res.data.preference.language);

        /**/

        console.log(res.data);

        //if(window.location.origin !== "http://192.168.15.200:3000"){

        //var obj = {
        //  name: res.data.name + " " + res.data.lastName,
        //  email: res.data.email,
        //  subscriptionType: "pro",
        //  location: window.location.origin
        //};

        //console.log(obj);
        //LogRocket.init('xfaecw/geeks-erp');
        //LogRocket.identify("erp_school_"+res.data.id, obj);

        //}


      }
    })
      .catch(async error => {

        if (error !== undefined) {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          if (error.response !== undefined) {
            if (error.response.status !== undefined) {
              if (error.response.status === 401) {
                //this.removeSession();
              }
            }
          }

        }


      });

  }

  //Para carregas as linguagens disponíveis no sistema.
  async getAllLanguage() {
    var language = await api
      .listLanguages()
      .then(res => {
        this.setState({ languages: res.data });
        this.props.dispatch({ type: "SET_LIST_LANGUAGE", data: res.data });
        return true;
      })
      .catch(error => {
        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;
      });
    return language;
  }

  //para retornar os dados da linguagem atual selecionaand
  getCurrentLanguage() {
    var language;
    for (let i = 0; i < this.state.languages.length; i++) {
      if (this.state.languages[i].id === this.state.languageSelected) {
        language = this.state.languages[i];
      }
    }
    return language;
  }

  //para buscar o arquivo da linguagem no sistema
  async getLanguageTexts(nameFile, id) {
    this.props.dispatch({ type: "SET_DATA_SET_PREFERENCE_LANGUAGE", data: id });

    //this.setState({languageSelected:id});
    var a = await api
      .languageText(nameFile)
      .then(res => {
        //this.setState({languageTexts:res.data});
        this.props.dispatch({ type: "SET_DATA_TRANSLATE", data: res.data });

        return true;
      })
      .catch(error => {
        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;
      });
    return a;
  }

  //Para pegar checar a licença
  async checkLicense() {

    return true;

  }

  //Para pegar as permissões
  async getLevelAccess() {

    var a = await api.getLevelAcess().then(async (res) => {

      await this.setState({ permitions: res.data });
      this.props.dispatch({ type: "SET_LEVEL_ACCESS", data: res.data });

      return true;

    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });

    return a;

  }

  async componentDidMount() {
    //para verificar o backend
    var a = await this.verificationInitial();

    //para pegar as linguagens disponíveis no sistema
    var b = await this.getAllLanguage();

    //para carregar a linguagem padrão
    var c = await this.getLanguageTexts("pt-br.json", "pt-br");

    //para verificar licença
    var d = await this.checkLicense();

    //para buscar todas as permissões
    var e = await this.getLevelAccess();

    //caso esteja tudo correto, exibe a página
    if (a === true && b === true && c === true && d === true && e === true) {
      this.setState({ preloader: false });
    } else {
      console.log("Verificação do brancked: " + a);
      console.log("Verificação do languages: " + b);
      console.log("Baixou arquivo de linguagem: " + c);
      console.log("Verificou licença: " + d);
      console.log("Verificou permissões: " + e);

      this.setState({ erroSystem: true });

      var errors = {};
      errors.a = a;
      errors.b = b;
      errors.c = c;
      errors.d = d;
      errors.e = e;

      this.setState({ errors: errors, preloader: false });
    }


  }

  render() {

    //    console.log('deu render');

    //Verifica se esta minimizado
    if (window.app.state.user !== undefined) {
      if (parseInt(window.app.state.user.preference.minimizeMenu) === 1) {
        $("body").removeClass("g-sidenav-show");
        $("body").removeClass("g-sidenav-pinned");
        $("body").addClass("g-sidenav-hidden");
        $(".sidenav-toggler").removeClass("active");
      } else {
        $("body").addClass("g-sidenav-show");
        $("body").addClass("g-sidenav-pinned");
        $("body").removeClass("g-sidenav-hidden");
        $(".sidenav-toggler").addClass("active");
      }
    }
    console.log('teste')
    return (
      <div>

        <GlobalFunctions />

        <DataStateInfos>

          {

            (this.state.preloader === false && this.state.errors === undefined) &&
            <React.Fragment>
              <Main loading={(n) => this.loading(n)} setAppState={(n) => this.setState(n)} getAppState={() => this.state} />
            </React.Fragment>

          }
          {
            /* preload od sistema 
            */
            //<Sugar customLoading={this.state.preloader} color="#9f102d" background="linear-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)"/>

          }

          {/*tela de erro caso exista*/}

          {(this.state.errors !== undefined) &&

            <ErrorPage errors={this.state.errors} />

          }
        </DataStateInfos>
      </div>
    );
  }
}

export default connect()(App);
